import React, { useEffect, useState } from "react";
import "./App.css";
import { WebCard } from './components/WebCard/WebCard.component';
import appsJson from "./hub.config.json";

export interface AppProps {
  href: string;
  label: string;
  text: string;
}


function App() {
  const [apps, setApps] = useState<AppProps[]>([]);

  useEffect(() => {
    setApps(appsJson as AppProps[]);
  }, [])
  return (
    <div className="App">
      <img src={process.env.PUBLIC_URL + "/logo.png"} alt="..." />
      <div id="content">
        <header>
          <h1>Seja bem vindo ao portal da Faço a Conta!</h1>
          <h2>Acesse nossos principais serviços!</h2>
        </header>
        <div id="uris">
          {apps.map(({ href, label, text}) => (
               <WebCard
                href={href}
                label={label}
                text={text}
              />
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
