import React, { HTMLAttributes } from "react";
import styles from "./WebCard.styles.css";
import { StringifyOptions } from 'querystring';

export interface WebCardProps extends React.DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
    label?: string;
    text?: string;
    href?: string;
}

export const WebCard = ({ label, text, ...props }: WebCardProps) => (
    <a className={styles["webCard-root"]} {...props}>
        <b>{label}</b>
        <p>{text}</p>
    </a>
);